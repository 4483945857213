// 色系
$red: #EA98BF;
$pink: #EA98BF;
$coral: #FF9999;
$blue: #70BED8; 
$logo_blue: #9ED6ED; 
$purple: #A5ACCF;
$green: #92CCC2;
$category_lightgreen: #B8DE59;
$yellow: #F6B88B;
$gray: #A0A0A0;
$darkgray: #c6c6c6;
$black: #393939;

$lightgreen: #f4fbfd;
$lightblue: #f5f7fd;
$gradation_primary: linear-gradient(90deg, $lightgreen 0%, $lightblue 100%);
$gradation_secondary: linear-gradient(-90deg, $lightgreen 0%, $lightblue 100%);

// 文字色
$letter_primary: $black;
$letter_secondary: $gray;

// 背景色
$bg_primary: $gradation_primary;
// $bg_secondary: $blue;

// テキスト選択色
$selection_bg: $logo_blue;
$selection_text_color: #fff;
$selection_img_bg: #70BED840;

// フォント系 light: 300, regular: 400, medium: 500, semibold: 600, bold: 700
$noto_yakuhan: "YakuHanJP", "Noto Sans JP", "Helvetica", "arial", sans-serif; 
$noto: "Noto Sans JP", "Helvetica", "arial", sans-serif;
$montserrat: "Montserrat", "Noto Sans JP", "Helvetica", "arial", sans-serif;
$fw_exlight_200: 200;
$fw_light_300: 300;
$fw_regular_400: 400;
$fw_medium_500: 500;
$fw_semibold_600: 600;
$fw_bold_700: 700;

//ヘッダーフッターの高さ
$header_height: clamp(60px, 9vw, 80px); 
$footer_height: 220px;
$footer_height_sp: 40px;

// 縦横比を固定する場合に使用
$ratio_16_9: 56.25%;
$ratio_4_3: 75%;
$ratio_3_2: 66.67%;
$ratio_1_1: 100%;

// メディアクエリー用
$pc_min: 1240px; // pcコンテンツ幅
$content_width_m: 1108px;
$content_width_s: 884px;
$tab_max: 920px; 
$sp_max: 712px;
$layout_min: 320px;
$body_pd: clamp(20px, 6vw, 100px);

/*=======================================
  z-index管理
=========================================

---- 99999 ----
#mouse_stalker

---- 1000 -----


---- 900 -----


----- 20 -----
#header_toggle
.header_symbol
#l__modal_wrapper .close_button

----- 15 -----
#sp_menu

----- 12 -----


----- 11 -----
.header_l

----- 10 -----
#header
#sp_menu

----- 9 -----
#pagetop_button

----- 8 -----
#footer

----- 7 -----
#l__funclub_button

------ 5 ------


------ 2 ------


------ 1 ------


------ -1 ------


------ -10 -----


------ -50 -----

------ -100 ----


------ -1000 ----


*/
