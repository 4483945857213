@use "../foundation/mixin" as m;
@use "../foundation/variables" as v;

//コンテンツ幅内でめいっぱい広げる
.u__content_width {
  @include m.content_width;
}
.u__content_width_m {
  @include m.content_width(v.$content_width_s);
}
.u__ib {
  display: inline-block;
}
.u__il {
  display: inline;
}
.u__bl {
  display: inline;
}
.u__none {
  display: none;
}
.u__pc_none {
  display: none;
  @include m.tab {
    display: block;
  }
  @include m.sp {
    display: block;
  }
}
.u__pc_only {
  display: block;
  @include m.tab {
    display: none;
  }
  @include m.sp {
    display: none;
  }
}
.u__tab_none {
  display: block;
  @include m.tab {
    display: none;
  }
  @include m.sp {
    display: block;
  }
}
.u__tab_only {
  display: none;
  @include m.tab {
    display: block;
  }
  @include m.sp {
    display: none;
  }
}
.u__sp_none {
  display: block;
  @include m.sp {
    display: none;
  }
}
.u__sp_only {
  display: none;
  @include m.sp {
    display: block;
  }
}
.u__color_blue {
  color: v.$blue;
}
.u__color_purple {
  color: v.$purple;
}
.u__color_green {
  color: v.$green;
}
.u__color_pink {
  color: v.$pink;
}
.u__color_yellow {
  color: v.$yellow;
}
.u__color_coral {
  color: v.$coral;
}
.u__color_gray {
  color: v.$gray;
}
.u__color_category_lightgreen {
  color: v.$category_lightgreen;
}
.u__background_blue {
  background-color: v.$blue;
}
.u__background_purple {
  background-color: v.$purple;
}
.u__background_green {
  background-color: v.$green;
}
.u__background_pink {
  background-color: v.$pink;
}
.u__background_yellow {
  background-color: v.$yellow;
}
.u__background_gray {
  background-color: v.$gray;
}
//== font-size 18px～32pxまで ==//
@for $i from 18 through 38 {
  .u__fs_#{$i} {
    font-size: #{$i}px;
  }
}
