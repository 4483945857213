@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*==============================
文字種
==============================*/
.c__common_text_primary {
  font-size: f.clamp_prf(14px, 16px);
  color: v.$letter_primary;
  font-weight: v.$fw_light_300;
  line-height: 175%;
  text-align: justify;
}
.c__common_text_secondary {
  font-size: f.clamp_prf(12px, 14px);
  color: v.$letter_primary;
  font-weight: v.$fw_light_300;
  line-height: 200%;
  text-align: justify;
}
.c__error_message {
  color: v.$red;
  font-weight: v.$fw_medium_500;
  font-size: f.clamp_prf(14px, 16px);
  line-height: 1.5;
}
// 改行しても背景がぶつ切りにならないやつ
.c__box_decoration_break_clone {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  box-shadow:
    8px 8px 10px 0 #ff1492,
    -5px -5px 5px 0 #00f,
    5px 5px 15px 0 #ff0;
}

/*==============================
見出し系
==============================*/
.c__head_primary {
  font-size: f.clamp_prf(30px, 34px);
  font-weight: v.$fw_semibold_600;
  font-family: v.$montserrat;
  color: v.$purple;
  text-align: center;
}
.c__head_secondary {
  font-size: f.clamp_prf(22px, 26px);
  font-weight: v.$fw_semibold_600;
  font-family: v.$montserrat;
  color: v.$purple;
}

/*==============================
アイコン
==============================*/
.c__svg_arrow {
  path {
    fill: none;
    stroke: v.$blue;
    stroke-width: 1px;
    stroke-dasharray: 66px 12px; // 線と隙間の長さを一気に指定 ※safari対策
    stroke-dashoffset: 78px; // dasharrayの合計値
  }
}
.c__svg_long_arrow {
  path {
    fill: none;
    stroke: v.$blue;
    stroke-width: 1px;
    stroke-dasharray: 158% 24px;
    stroke-dashoffset: calc(158% + 24px);
  }
}

// fvとプロフィール両方に影響するので注意
.c__svg_cross {
  // width: 100px;
  width: 20px;
  height: 20px;
  path {
    animation: cross 5s ease-out infinite;
    // stroke-dasharray: 15px 15px;
    // stroke-dashoffset: 20px;
    fill: none;
    stroke: v.$logo_blue;
    stroke-width: 2px;
    @include m.anim_delay(0.3);
    @keyframes cross {
      0% {
        stroke-dasharray: 20px 16px; // 線と隙間の長さを一気に指定 ※safari対策
        stroke-dashoffset: 36px; // dasharrayの合計値
      }
      40% {
        stroke-dashoffset: 0;
      }
    }
  }
  &.quick {
    path {
      animation: cross_reverse 5s infinite;
      @include m.anim_delay(0.3);
      stroke-dasharray: 6 6; // 線と隙間の長さを一気に指定 ※safari対策
      stroke-dashoffset: 6;

      // 6で消える
      // 0で現れる
      @keyframes cross_reverse {
        0% {
          stroke-dashoffset: 6; // dasharrayの合計値
        }
        20% {
          stroke-dashoffset: 0; // dasharrayの合計値
        }
        25% {
          stroke-dashoffset: 0; // dasharrayの合計値
        }
        30% {
          stroke-dashoffset: -6; // dasharrayの合計値
        }
        100% {
          stroke-dashoffset: -6;
        }
      }
    }
  }
  &.no_animation {
    path {
      animation: none;
    }
  }
}
.c__svg_cross_fv {
  path {
    animation: cross_fv 5s ease-out infinite;
    fill: none;
    stroke: v.$logo_blue;
    stroke-width: 2px;
    @include m.anim_delay(0.2);
    @keyframes cross_fv {
      0% {
        stroke-dasharray: 20px 16px; // 線と隙間の長さを一気に指定 ※safari対策
        stroke-dashoffset: 36px; // dasharrayの合計値
      }
      40% {
        stroke-dashoffset: 0;
      }
    }
  }
  &.quick {
    path {
      animation: cross_reverse_fv 4s infinite;
      @include m.anim_delay(0.2);
      stroke-dasharray: 10 10; // 線と隙間の長さを一気に指定 ※safari対策
      stroke-dashoffset: 10;

      // 6で消える
      // 0で現れる
      @keyframes cross_reverse_fv {
        0% {
          stroke-dashoffset: 10; // dasharrayの合計値
        }
        10% {
          stroke-dashoffset: 0; // dasharrayの合計値
        }
        20% {
          stroke-dashoffset: -10; // dasharrayの合計値
        }
        100% {
          stroke-dashoffset: -10;
        }
      }
    }
  }
  &.no_animation {
    path {
      animation: none;
    }
  }
}
/*==============================
ボタン系
==============================*/
.c__primary_button {
  width: 200px;
  height: clamp(60px, 14vw, 60px);
  border: 1px solid v.$blue;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.5s;
  .link_name {
    font-size: clamp(16px, 5vw, 16px);
    font-family: v.$montserrat;
    font-weight: v.$fw_semibold_600;
    letter-spacing: 0.96px;
    margin-bottom: -0.2em;
    z-index: 1;
    color: v.$blue;
  }
  .c__svg_arrow {
    position: absolute;
    right: -28px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: auto;
    z-index: 1;
  }
  @include m.hover_device {
    &:hover {
      box-shadow: 0 0px 6px 0 #87bcce;
      path {
        stroke-dashoffset: 0px;
        transition: 0.7s ease;
      }
    }
  }
}
.c__sns_service_icon {
  display: block;
  margin: auto 0;
  display: flex;
  gap: 20px;
  .service_link {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 100vh;
    transition: 0.5s;
    @include m.hover_device {
      &:hover {
        box-shadow: 0 0px 6px 0 #87bcce;
      }
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.x {
      width: 17px;
    }
    &.yt {
      width: 22px;
    }
    &.ig {
      width: 20px;
    }
  }
}
.c__navigation_button {
  position: relative;
  width: clamp(58px, 16vw, 80px);
  height: clamp(58px, 16vw, 80px);
  flex-shrink: 0;
  .c__svg_arrow {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    transition: 0.5s;
  }
  &.circle {
    border-radius: 100vh;
    border: 1px solid v.$blue;
    transition: 0.5s;
  }
  &.left .c__svg_arrow {
    transform: translate(-50%, -50%) scaleX(-1);
  }
  &.down .c__svg_arrow {
    transform: translate(-45%, -50%) rotate(90deg);
  }
  &.up .c__svg_arrow {
    transform: translate(-55%, -50%) rotate(-90deg);
  }
  &.small {
    width: 40px;
    height: 40px;
    .c__svg_arrow path {
      stroke-width: 2px;
    }
  }
  &.disabled {
    pointer-events: none;
    .c__svg_arrow path {
      stroke: #ccc;
    }
  }
  @include m.hover_device {
    &:hover {
      &.circle {
        box-shadow: 0 0px 6px 0 #87bcce;
      }
      .c__svg_arrow path {
        stroke-dashoffset: 0px;
        transition: 0.7s ease;
      }
    }
  }
}
/*==============================
一覧系
==============================*/
.c__categories_list {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(30px, 4vw, 30px) clamp(80px, 4vw, 80px);
  align-items: center;
  justify-content: center;
  max-width: 100%;
  font-family: v.$montserrat;
  font-size: f.clamp_prf(16px, 18px);
  font-weight: v.$fw_bold_700;
  overflow: hidden;
  padding-bottom: 20px;
  @include m.sp {
    margin-right: clamp(0px, 24vw, 170px);
    margin-left: clamp(0px, 24vw, 170px);
  }
  @media (max-width: (600px)) {
    margin-right: clamp(0px, 19vw, 120px);
    margin-left: clamp(0px, 19vw, 120px);
  }
  @media (max-width: (490px)) {
    margin-right: clamp(0px, 12vw, 60px);
    margin-left: clamp(0px, 12vw, 60px);
  }
  @media (max-width: (400px)) {
    margin-right: clamp(0px, 4vw, 20px);
    margin-left: clamp(0px, 4vw, 20px);
  }
  @media (max-width: (340px)) {
    margin-right: 0;
    margin-left: 0;
  }
  .category_item {
    display: inline-block;
    position: relative;
    pointer-events: none;
    &:not(:last-child):before {
      content: "";
      position: absolute;
      top: 50%;
      right: calc(clamp(80px, 4vw, 80px) / 2 * -1);
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 100vh;
      background: v.$gray;
    }
    .category_buttons {
      pointer-events: auto;
    }
    svg {
      position: absolute;
      width: 64px;
      height: 4px;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
      @include m.sp {
        width: 36px;
        bottom: -10px;
      }
      path {
        fill: none;
        stroke-width: 2px;
        stroke-linecap: "round";
        stroke: #d9d9d9;
        stroke-dasharray: 64px 64px; // 線と隙間の長さを一気に指定 ※safari対策
        stroke-dashoffset: 64px;
        transition: 0.5s;
      }
    }
    &.active {
      svg path {
        stroke-dashoffset: 0px;
      }
    }
    @include m.hover_device {
      &:hover {
        svg path {
          stroke-dashoffset: 0px;
        }
      }
    }
  }
}
.c___news_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // 最初の要素では余白をなくす
  .news_item {
    display: flex;
    flex-direction: column;
    gap: f.clamp_prf(6px, 6px);
    padding: f.clamp_prf(30px, 30px) 0;
    width: 100%;
    position: relative;
    &:first-child {
      padding-top: 0;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: v.$gray;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #86cbe2;
      transform: scaleX(0);
      z-index: 1;
      transition: 1s ease;
      transform-origin: left;
    }
    .sub_info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: f.clamp_prf(28px, 28px);
      font-weight: v.$fw_bold_700;
      font-size: f.clamp_prf(14px, 14px);
      font-family: v.$montserrat;
      letter-spacing: f.px2em(14, 0.84);
      .date {
        white-space: nowrap;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: -16px;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background: #a0a0a0;
          border-radius: 100vh;
        }
      }
      .category {
        white-space: nowrap;
      }
    }
    .title {
      position: relative;
      padding-right: f.clamp_prf(60px, 104px);
      width: 100%;
      font-weight: v.$fw_medium_500;
      font-size: f.clamp_prf(14px, 16px);
      text-align: justify;
      line-height: 175%;
      @include m.line_clamp(2);

      @include m.sp {
        @include m.line_clamp(3);
      }
    }
    .c__svg_arrow {
      position: absolute;
      bottom: 30px;
      right: 0;
      width: f.clamp_prf(40px, 40px);
      height: auto;
      z-index: 1;
      @include m.sp {
        bottom: 50%;
        transform: translateY(100%);
      }
    }
    &:hover {
      &:after {
        transform: scaleX(1);
      }
      .c__svg_arrow path {
        stroke-dashoffset: 0px;
        transition: 1s ease;
      }
    }
  }
}

/*=======================================
  コンテンツエリア
=======================================*/

.c__article_body {
  font-family: v.$noto;
  font-size: f.clamp_prf(14px, 16px);
  font-weight: v.$fw_light_300;
  color: v.$letter_primary;
  h2 {
    font-size: f.clamp_prf(20px, 26px);
    font-weight: v.$fw_semibold_600;
    margin-top: f.clamp_prf(18px, 20px);
    line-height: 1.75;
  }
  h3 {
    font-size: f.clamp_prf(18px, 24px);
    font-weight: v.$fw_medium_500;
    margin-top: f.clamp_prf(18px, 20px);
    line-height: 1.75;
  }
  h4 {
    font-size: f.clamp_prf(17px, 22px);
    font-weight: v.$fw_medium_500;
    margin-top: f.clamp_prf(18px, 20px);
    line-height: 1.75;
  }
  h5 {
    font-size: f.clamp_prf(16px, 20px);
    font-weight: v.$fw_medium_500;
    margin-top: f.clamp_prf(18px, 20px);
    line-height: 1.75;
  }
  h6 {
    font-size: f.clamp_prf(15px, 20px);
    font-weight: v.$fw_medium_500;
    margin-top: f.clamp_prf(18px, 20px);
    line-height: 1.75;
  }
  p {
    margin-top: f.clamp_prf(18px, 20px);
    line-height: 1.75;
    text-align: justify;
    strong {
      font-weight: v.$fw_medium_500;
    }
    em {
      font-style: italic;
    }
    u {
      text-decoration: underline;
    }
    s {
      text-decoration: line-through;
    }
    a {
      text-decoration: underline;
      transition: 0.15s;
      @include m.anchor_color(v.$blue);
      &:hover {
        opacity: 0.7;
      }
    }
    img {
      width: auto;
      max-width: 100%;
    }
  }
  ul {
    margin-top: f.clamp_prf(18px, 24px);
    list-style-type: disc;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 14px;
      line-height: 1.6;
    }
  }
  ol {
    margin-top: f.clamp_prf(18px, 24px);
    list-style-type: decimal;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 14px;
      line-height: 1.6;
    }
  }
  figcaption {
    font-size: 14px;
    line-height: 1.75;
  }
  .wp-block-embed-youtube .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  iframe {
    width: 100%;
    height: clamp(200px, 45.5vw, 496px);
    aspect-ratio: 16/9;
    @include m.sp {
      height: clamp(136px, 42vw, 400px);
    }
  }
  .article_thumbnail {
    img,
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.c__pager {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: v.$fw_semibold_600;
  font-family: v.$montserrat;
  font-size: f.clamp_prf(14px, 16px);
  .disabled {
    display: none;
  }
  .elipsis {
    letter-spacing: 0.05em;
  }
  .page_num {
    transition: 0.5s;
    padding: f.clamp_prf(8px, 10px);
    position: relative;
    color: #333;
    &:before {
      transition: 0.5s;
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: max(100%, 10px);
      height: 1px;
      background-color: transparent;
      transition: all 0.3s;
      transform: scale(0, 1) translateX(-50%);
      transform-origin: center top;
      @include m.sp {
      }
    }
    &.active {
      pointer-events: none;
      color: v.$blue;
      &:before {
        transform: scale(1, 1) translateX(-50%);
        background-color: #fff;
      }
    }
    @include m.hover_device {
      &:hover {
        color: v.$blue;

        &:before {
          transform: scale(1, 1) translateX(-50%);
          background-color: #fff;
        }
      }
    }
  }
  .prev_link,
  .next_link {
    display: block;
    position: relative;
    width: clamp(10px, 3.3em, 44px);
    height: clamp(10px, 1.3em, 30px);
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-image: url(/assets/img/common/navigation/arrow.svg);
      background-repeat: no-repeat;
      width: clamp(10px, 3.3em, 44px);
      height: clamp(10px, 1.3em, 30px);
      position: absolute;
      top: 50%;
      left: 0;
      transition: 0.3s;
    }
  }
  .prev_link {
    margin-right: clamp(16px, 0.98vw, 20px);
    &:before {
      left: 0;
      transform: translate(0, -60%) scaleX(-1);
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: translate(-4px, -60%) scaleX(-1);
        }
      }
    }
  }
  .next_link {
    margin-left: clamp(16px, 0.98vw, 20px);
    &:before {
      left: 0;
      transform: translate(0, -60%);
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: translate(4px, -60%);
        }
      }
    }
  }
}
.c__article_prev_next {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: f.clamp_prf(60px, 60px);
  gap: f.clamp_prf(20px, 30px);

  .pager_link_prev,
  .pager_link_next {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    svg {
      position: absolute;
    }
    &.disabled {
      pointer-events: none;
      path {
        stroke: #ccc;
      }
    }
    &:hover {
      path {
        stroke-dashoffset: 0px;
        transition: 0.7s ease;
      }
    }
  }
  .pager_link_prev svg {
    //左右反転
    transform: scaleX(-1);
  }
}
/*=======================================
  フォーム系
=======================================*/
@mixin form_base($height, $font_size: 16px) {
  width: 100%;
  height: $height;
  font-family: v.$noto; //明示
  font-size: $font_size;
  padding: f.clamp_prf(20px, 30px) 16px;
  transition: 0.2s ease;
  color: v.$letter_primary;
  -webkit-appearance: none; //safariの角丸対策
  border-radius: 0px; //safariの角丸対策
  background-color: transparent;
  border: none;
  outline: 0;
  line-height: 1.5;
  &:focus {
    border: none;
  }
  &::placeholder {
    color: #d9d9d9;
    font-family: v.$noto; //明示
    font-size: $font_size;
  }
}
.c__contact_label {
  font-size: 16px;
  font-weight: v.$fw_medium_500;
  color: v.$letter_primary;
  letter-spacing: 0.06em;
  cursor: text;
  position: relative;
  &.required::after {
    position: absolute;
    top: calc(-1em - 6px);
    top: -1.5em;
    left: 0;
    content: "必須";
    color: v.$blue;
    font-size: f.clamp_prf(12px, 14px);
  }
}
.c__contact_textbox {
  @include form_base(f.clamp_prf(60px, 80px), f.clamp_prf(14px, 16px));
}
.c__contact_textarea {
  @include form_base(120px, f.clamp_prf(14px, 16px));
  max-height: 480px;
  resize: none;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
}
.c__contact_radio,
.c__contact_checkbox {
  display: none;
  + label {
    position: relative;
    padding-left: f.clamp_prf(26px, 36px);
    cursor: pointer;
  }
  + label:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-0%, -44%);
    content: "";
    border: 1px solid v.$blue;
    border-radius: 100vh;
    width: 20px;
    height: 20px;
    opacity: 1;
    transition: 0.3s;
  }
  &:checked + label:before {
    background-color: v.$blue;
  }
  @include m.hover_device {
    &:hover {
      + label:before {
        background-color: #e2f7ff;
      }
      &:checked + label:before {
        background-color: v.$blue;
      }
    }
  }
}

.c__contact_select {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  &:after {
    // 三角
    position: absolute;
    right: 15px;
    width: 10px;
    height: 7px;
    background-color: #fff;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: "";
    pointer-events: none;
  }
  select {
    appearance: none; //各ブラウザのデフォルトをリセット
    width: 100%;
    @include form_base(60px, 16px);
    cursor: pointer;
    color: v.$letter_primary;
    -webkit-appearance: none; //safariの角丸対策
    border-radius: 0px; //safariの角丸対策
    border: none;
    outline: 0;
  }
  option {
    color: v.$letter_primary;
    font-family: v.$noto; //明示
    font-size: 16px;
    background: #333;
  }
}

/*=======================================
  アニメーション系
=======================================*/
.c__js_fade {
  transform: translate(0, 30px);
  opacity: 0;
  transition: 0.7s cubic-bezier(0.33, 1, 0.68, 1);
  &.active {
    transform: translate(0, 0);
    opacity: 1;
  }
}

// ディレイを付けたい場合
// 親要素に c__js_fade_delay を付け、子要素に js__delay_child delay2 のようなクラスを付ける
.c__js_fade_delay {
  .js__delay_child {
    transform: translate(0, 20px);
    opacity: 0;
  }
  &.active {
    .js__delay_child {
      transform: translate(0, 0);
      opacity: 1;
      transition: 0.7s cubic-bezier(0.33, 1, 0.68, 1);
      @include m.tran_delay(0.3);
    }
  }
}

.c__js_fade_repeat {
  transform: translate(0, 40px);
  opacity: 0;
  transition: 0.5s ease;
  &.active {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
  }
  @include m.tran_delay(0.3);
}
.c__js_blur {
  .c__js_blur_child {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    filter: blur(10px);
  }
  &.active {
    .c__js_blur_child {
      opacity: 1;
      filter: blur(0px);
      @include m.tran_delay(0.2);
    }
  }
}
.js__transition {
  .js__transition_child {
    // .js__transition_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.tran_delay(0.2);
  }
}
.js__animation {
  .js__animation_child {
    // .js__animation_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.anim_delay(0.2);
  }
}
.js__parallax_container {
  transition: 0.25s cubic-bezier(0.08, 0.93, 0.42, 0.96);
}

// あくまで表示非表示の切り替えのみ行う
.js__load_required {
  opacity: 0;
  pointer-events: none;
  height: 50vh;
  transform: translate(0, 10px);
  transition: 0.5s ease;
  &.loaded {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
    pointer-events: auto;
    height: auto;
  }
}
