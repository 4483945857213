@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*=======================================
  main
=======================================*/
#p__home {
  #first_view {
    position: relative;
    width: 100%;
    height: f.svh(100);
    height: 100svh;
    overflow: hidden;
    .fv_overlay {
      position: relative;
      z-index: 5;
      pointer-events: none;
      width: 400%;
      height: 100%;
      background: linear-gradient(270deg, rgba(244, 251, 253, 1) 25%, #baebf2ff 45%, #baebf2ff 50%, #baebf200 75%);
      transform: translateX(-75%);
      transition: 4.5s cubic-bezier(0.33, 1, 0.68, 1);
      &.active {
        transform: translateX(0);
      }
      @include m.sp {
        background: linear-gradient(270deg, rgba(244, 251, 253, 1) 15%, #baebf2ff 40%, #baebf2ff 50%, #baebf200 75%);
      }
    }
    .fv_bg {
      position: absolute;
      width: 100%;
      height: 150%;
      // height: f.svh(120);
      // height: 120svh;
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      object-fit: cover;
      opacity: 0;
      transition: 1s ease;
      filter: blur(10px);
      &.active {
        filter: blur(0);
        opacity: 1;
      }
      @include m.tab {
        // transform: translate(-51%, -46%);
      }
      @include m.sp {
        // display: none;
        width: 200vw;
        transform: translate(-60%, 10%);
      }
    }
    .fv_bg {
      z-index: 1;
    }
    // .fv_artist_image {
    //   z-index: 3;
    // }
    // .fv_bg_sp {
    //   display: none;
    //   position: absolute;
    //   width: auto;
    //   height: clamp(600px, f.svh(120), 2500px);
    //   height: clamp(600px, 120svh, 2500px);
    //   bottom: clamp(-110px, -27vw, -96px);
    //   left: 50%;
    //   transform: translateX(-51%);
    //   object-fit: contain;
    //   z-index: 1;
    //   transition: 2s ease;
    //   filter: blur(10px);
    //   opacity: 0;
    //   &.active {
    //     filter: blur(0);
    //     opacity: 1;
    //   }
    //   @include m.sp {
    //     display: block;
    //     opacity: 0;
    //     &.active {
    //       opacity: 1;
    //     }
    //   }
    // }
    // .fv_decoration {
    //   position: absolute;
    //   width: f.svh(142);
    //   width: 142svh;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-49%, -46%);
    //   object-fit: contain;
    //   z-index: 2;
    //   opacity: 0;
    //   transition: 0.5s;
    //   @include m.sp {
    //     display: none;
    //   }
    //   path {
    //     fill: none;
    //     stroke: v.$logo_blue;
    //     stroke-width: 1.5px;
    //     &.bottom {
    //       // 初回のパスの描画
    //       transition: 4s cubic-bezier(0.65, 0, 0.35, 1);
    //       stroke-dasharray: 1870px;
    //       stroke-dashoffset: 1870px;
    //     }
    //     &.above {
    //       // 初回のパスの描画
    //       transition: 6s cubic-bezier(0.22, 1, 0.36, 1);
    //       transition-delay: 4s;
    //       stroke-dasharray: 1110px;
    //       stroke-dashoffset: 1110px;
    //     }
    //   }
    //   circle {
    //     fill: v.$logo_blue;
    //     stroke: none;
    //   }
    //   line {
    //     fill: none;
    //     stroke: v.$logo_blue;
    //     stroke-width: 1.5px;
    //   }

    //   &.active {
    //     opacity: 1;
    //     path {
    //       stroke-dashoffset: 0;
    //       &.bottom {
    //         animation: fv_path_bottom 12s ease-in-out infinite;
    //         animation-delay: 12s; // 開始タイミング fv_decorationのsetTimeoutとaboveのtransition-delayの合計
    //         @keyframes fv_path_bottom {
    //           0% {
    //             stroke-dasharray: 1810px 60px; // 線と隙間の長さを一気に指定 ※safari対策
    //             stroke-dashoffset: 1870px; // dasharrayの合計値
    //           }
    //           40% {
    //             stroke-dashoffset: 0;
    //           }
    //           100% {
    //             stroke-dashoffset: 0;
    //           }
    //         }
    //       }
    //       &.above {
    //         animation: fv_path_above 12s ease-out infinite;
    //         animation-delay: 12s; // 開始タイミング bottomのtransitionとaboveのtransition-delayの合計
    //         @keyframes fv_path_above {
    //           0% {
    //             stroke-dasharray: 1100px 0px; // 線と隙間の長さを一気に指定 ※safari対策
    //             stroke-dashoffset: 1100px; // dasharrayの合計値
    //           }
    //           35% {
    //             stroke-dasharray: 1100px 0px; // 線と隙間の長さを一気に指定 ※safari対策
    //             stroke-dashoffset: 1100px; // dasharrayの合計値
    //           }
    //           70% {
    //             stroke-dashoffset: 0px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .fv_logo {
      z-index: 3;
      position: absolute;
      width: clamp(320px, 35vw, 560px);
      top: 50%;
      left: 50%;
      transform: translate(-94%, -20%);
      opacity: 0;
      transition: 1s ease;
      filter: blur(10px);
      &.active {
        filter: blur(0);
        opacity: 1;
      }
      @include m.tab {
        transform: translate(-98%, -78%);
      }
      @include m.sp {
        width: clamp(260px, 74vw, 320px);
        top: clamp(80px, f.svh(14), 90px);
        top: clamp(80px, 14svh, 90px);
        transform: translateX(-50%);
      }
    }
  }
  .white_bg {
    @include m.body_margin;
    background-color: #fff;
  }
  .home_news {
    padding-top: f.clamp_prf(40px, 180px);
    .c___news_list {
      margin-top: f.clamp_prf(40px, 108px);
    }
    .c__primary_button {
      margin: f.clamp_prf(50px, 50px) 28px 0 auto;
      @include m.sp {
        margin: clamp(50px, 3.23vw, 50px) auto 0;
        transform: translateX(-14px);
      }
    }
  }
  .home_information {
    margin-top: f.clamp_prf(60px, 100px);
    padding-bottom: f.clamp_prf(100px, 200px);
    display: flex;
    @include m.tab {
      flex-direction: column;
      gap: f.clamp_prf(60px, 60px);
    }
    .c__head_primary {
      text-align: left;
      font-size: clamp(23px, 7vw, 28px);
      white-space: nowrap;
    }
    .info_l {
      flex-grow: 1;
      flex-shrink: 0;
      width: 50%;
      @include m.tab {
        width: 100%;
      }
      .social_media_wrapper {
        display: flex;
        flex-direction: column;
        gap: f.clamp_prf(20px, 20px);
        margin-top: f.clamp_prf(20px, 40px);
        .link {
          display: flex;
          gap: clamp(10px, 4.5vw, 30px);
          .icon {
            position: relative;
            width: clamp(70px, 21vw, 100px);
            height: clamp(70px, 21vw, 100px);
            flex-shrink: 0;
            border-radius: 100vh;
            border: 1px solid v.$blue;
            transition: 0.5s;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              &.x {
                width: clamp(34px, 9vw, 42px);
              }
              &.ig {
                width: clamp(32px, 9vw, 40px);
              }
              &.yt {
                width: clamp(37px, 9vw, 46px);
              }
            }
          }
          .link_info {
            margin-top: 12px;
            flex-grow: 1;
            .link_name {
              font-size: f.clamp_prf(14px, 16px);
              font-weight: v.$fw_medium_500;
            }
            .id {
              margin-top: 10px;
              font-size: f.clamp_prf(14px, 16px);
              font-weight: v.$fw_light_300;
            }
            .c__svg_long_arrow {
              margin-top: 8px;
              width: clamp(156px, 20vw, 218px);
              @include m.tab {
                width: clamp(156px, 47vw, 218px);
              }
            }
          }
          &:hover {
            .icon {
              box-shadow: 0 0px 6px 0 #87bcce;
            }
            .c__svg_long_arrow path {
              stroke-dashoffset: 0px;
              transition: 1s cubic-bezier(0.33, 1, 0.68, 1);
            }
          }
        }
      }
    }
    .info_r {
      flex-grow: 1;
      flex-shrink: 0;
      width: 50%;
      @include m.tab {
        width: 100%;
      }
      .announce {
        .address_annotation {
          margin-top: f.clamp_prf(20px, 40px);
          font-size: f.clamp_prf(14px, 16px);
        }
        .address {
          margin-top: 10px;
          @include m.sp_s {
            font-size: f.clamp_prf(13px, 16px);
          }
        }
      }
      .contact {
        margin-top: f.clamp_prf(60px, 64px);
        .contact_info {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          gap: 14px;
          @include m.tab {
            margin-top: -10px;
            align-items: center;
          }
          @include m.sp {
            margin: auto;
            align-items: flex-end;
          }
        }
        .contact_annotation {
          margin-top: 20px;
          font-size: clamp(14px, 1.5vw, 16px);
        }
      }
    }
  }
}
#p__news_index {
  .c__categories_list {
    @include m.content_width(v.$content_width_s);
    margin-top: f.clamp_prf(40px, 110px);
  }
  .c___news_list {
    @include m.content_width(v.$content_width_s);
    margin-top: f.clamp_prf(54px, 88px);
  }
  .c__pager {
    margin-top: f.clamp_prf(60px, 80px);
  }
}
#p__news_show {
  .article_wrapper {
    @include m.content_width(v.$content_width_s);
    margin-top: f.clamp_prf(60px, 112px);
  }
  .article_head_info {
    display: flex;
    flex-direction: column;
    gap: f.clamp_prf(6px, 6px);
    padding: f.clamp_prf(30px, 30px) 0;
    width: 100%;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: v.$gray;
    }
    .info_above {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: f.clamp_prf(28px, 28px);
      font-weight: v.$fw_bold_700;
      font-size: f.clamp_prf(14px, 14px);
      font-family: v.$montserrat;
      letter-spacing: f.px2em(14, 0.84);
      .date {
        white-space: nowrap;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: -16px;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background: #a0a0a0;
          border-radius: 100vh;
        }
      }
      .category {
        white-space: nowrap;
      }
    }
    .info_bottom {
      display: flex;
      gap: f.clamp_prf(40px, 54px);
      align-items: center;
      @include m.tab {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
      }
    }
    .title {
      width: 100%;
      font-weight: v.$fw_medium_500;
      font-size: f.clamp_prf(14px, 16px);
      text-align: justify;
      line-height: 175%;
    }
    .share {
      display: flex;
      gap: f.clamp_prf(12px, 16px);
      align-items: center;
      justify-content: flex-end;
      width: f.clamp_prf(160px, 180px);
      height: 30px;
      @include m.tab {
        justify-content: flex-start;
      }
      .x_icon {
        flex-shrink: 0;
        width: f.clamp_prf(20px, 25px);
      }
      .share_text {
        flex-shrink: 0;
        font-size: f.clamp_prf(14px, 16px);
        font-weight: v.$fw_medium_500;
        font-family: v.$montserrat;
      }
      .c__svg_arrow {
        flex-shrink: 0;
        width: f.clamp_prf(30px, 40px);
      }
      @include m.hover_device {
        &:hover {
          .c__svg_arrow path {
            stroke-dashoffset: 0px;
            transition: 0.7s ease;
          }
        }
      }
    }
  }
  .c__article_body {
    margin-top: f.clamp_prf(30px, 60px);
    padding-bottom: f.clamp_prf(30px, 60px);
    border-bottom: 1px solid v.$gray;
  }
  .c__primary_button {
    width: clamp(140px, 30vw, 200px);
    height: clamp(50px, 11vw, 60px);
  }
}
#p__profile {
  .profile_inner {
    @include m.content_width(v.$content_width_m);
    display: flex;
    gap: f.clamp_prf(60px, 100px);
    margin-top: f.clamp_prf(50px, 74px);
    @include m.tab {
      flex-direction: column;
      padding-left: clamp(16px, 4vw, 100px);
      padding-right: clamp(16px, 4vw, 100px);
    }
  }
  .profile_l {
    width: f.clamp_prf(300px, 450px);
    flex-shrink: 0;
    @include m.tab {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
    .profile_image_wrapper {
      position: relative;
      height: f.clamp_prf(300px, 450px);
      @include m.tab {
        height: clamp(220px, 79vw, 400px);
      }
      .profile_image {
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: f.clamp_prf(10px, 20px);
      }
      .decoration_image {
        display: block;
        width: 122%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-7%, -7%);
        .stroke {
          fill: none;
          stroke-width: 1.5px;
          stroke: v.$logo_blue;
          transition: 0.5s;
        }
        .fill {
          fill: v.$logo_blue;
          stroke: none;
          transition: 0.5s;
        }
        .black {
          fill: black;
          stroke-width: 1.5px;
          stroke: black;
          transition: 0.5s;
        }
        .circle1 {
          animation: blob_rotate 11s linear infinite;
          transform-origin: 47% 48%;
        }
        .circle2 {
          animation: blob_scale 4s ease infinite;
          transform-origin: 47% 48%;
        }
        @include m.tab {
          width: 115%;
          transform: translate(-5%, -5%);
          .circle1 {
            animation: blob_rotate 14s linear infinite;
          }
        }
        @keyframes blob_rotate {
          100% {
            transform: rotate(360deg);
          }
        }
        @keyframes blob_scale {
          0% {
            transform: scale(1.00);
          }
          50% {
            transform: scale(0.96);
          }
          100% {
            transform: scale(1.00);
          }
        }
      }
    }
    .introduction_wrapper {
      margin-top: clamp(20px, 8vw, 40px);
      padding: f.clamp_prf(28px, 64px) f.clamp_prf(20px, 40px) f.clamp_prf(24px, 44px) f.clamp_prf(28px, 62px);
      border-right: 1px solid v.$blue;
      border-bottom: 1px solid v.$blue;
      border-left: 1px solid v.$blue;
      border-radius: 0 0 20px 20px;
      .name {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: f.clamp_prf(10px, 14px);
        flex-wrap: wrap;
        .ja {
          font-size: f.clamp_prf(33px, 36px);
          font-weight: v.$fw_bold_700;
          letter-spacing: f.px2em(36, 4.76);
        }
        .en {
          font-size: f.clamp_prf(13px, 16px);
          color: v.$blue;
          font-family: v.$montserrat;
          font-weight: v.$fw_medium_500;
        }
      }
      .table {
        margin-top: f.clamp_prf(30px, 44px);
        display: flex;
        gap: f.clamp_prf(10px, 46px);
        font-size: clamp(12px, 1.3vw, 16px);
        line-height: 237.5%;
        @include m.tab {
          font-size: clamp(12px, 3.5vw, 16px);
        }
        .head {
          color: v.$blue;
          font-weight: v.$fw_medium_500;
          flex-shrink: 0;
          line-height: inherit;


          display: flex;
          flex-direction: column;
          gap: 22px;
          span {
            display: block;
          }
        }
        .content {
          font-weight: v.$fw_light_300;
          width: 100%;
          line-height: inherit;


          display: flex;
          flex-direction: column;
          gap: 22px;
          span {
            display: block;
            .multi_line_bottom {
              margin-top: 14px;
            }
          }
        }
      }
    }
    .voice_sample_wrapper {
      margin-top: f.clamp_prf(60px, 100px);
      .voice_sample_head {
        font-family: v.$montserrat;
        font-size: f.clamp_prf(16px, 20px);
        font-weight: v.$fw_semibold_600;
        text-align: center;
      }
      .sample_item_list {
        margin-top: f.clamp_prf(40px, 40px);
        display: flex;
        flex-direction: column;
        gap: f.clamp_prf(20px, 26px);
        .title {
          font-size: 14px;
          font-weight: v.$fw_medium_500;
        }
        .player {
          margin-top: 6px;
          padding: 0 f.clamp_prf(26px, 42px);
          width: 100%;
          height: f.clamp_prf(50px, 60px);
          border: solid 1px v.$blue;
          border-radius: 100vh;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: f.clamp_prf(14px, 22px);
          .play_button {
            width: f.clamp_prf(18px, 20px);
            padding: 4px;
            margin-right: -4px;
            flex-shrink: 0;
            cursor: pointer;
            pointer-events: auto;
          }
          .time {
            font-size: f.clamp_prf(12px, 14px);
            font-weight: v.$fw_semibold_600;
            font-family: v.$montserrat;
            flex-shrink: 0;
            letter-spacing: f.px2em(14, 0.84);
            width: clamp(76px, 3vw, 86px);
            white-space: nowrap;
            .current_time,
            .total_time {
              display: inline-block;
              width: clamp(32px, 3vw, 36px);
            }
          }
          .progress_bar {
            position: relative;
            width: 100%;
            height: auto;
            .current_progress {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 0%;
              height: 4px;
              background-color: #000;
              border-radius: 100vh;
              z-index: 1;
              pointer-events: none;
            }
            .total_progress {
              position: absolute;
              width: 100%;
              border-radius: 100vh;
              height: 4px;
              padding: 8px 0;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              cursor: pointer;
              &:before {
                content: "";
                width: 100%;
                height: 4px;
                background-color: #d9d9d9;
                border-radius: 100vh;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      .fold_button {
        display: none;
        margin: 0 auto;
        @include m.tab {
          display: block;
        }
      }
      .sample_item_list.folded {
        .sp_fold {
          @include m.tab {
            display: none;
          }
        }
      }
    }
  }
  .profile_r {
    flex-grow: 1;
    .history_categories_list {
      display: flex;
      flex-wrap: nowrap;
      gap: clamp(30px, 4.5vw, 80px);
      align-items: center;
      justify-content: center;
      max-width: 100%;
      font-family: v.$montserrat;
      font-size: clamp(12px, 1.6vw, 18px);
      font-weight: v.$fw_bold_700;
      @include m.tab {
        font-size: clamp(12px, 3.75vw, 18px);
        gap: clamp(30px, 9.5vw, 80px);
      }
      .category_item {
        display: inline-block;
        position: relative;
        pointer-events: none;
        &:not(:last-child):before {
          content: "";
          position: absolute;
          top: 50%;
          right: calc(clamp(36px, 6vw, 80px) / 2 * -1);
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          border-radius: 100vh;
          background: v.$gray;
        }
        .category_buttons {
          position: relative;
          pointer-events: auto;
        }
        svg {
          position: absolute;
          width: 64px;
          height: 4px;
          left: 50%;
          bottom: -20px;
          transform: translateX(-50%);
          @include m.sp {
            bottom: -16px;
          }
          path {
            fill: none;
            stroke-width: 2px;
            stroke-linecap: "round";
            stroke: #d9d9d9;
            stroke-dasharray: 64px 64px; // 線と隙間の長さを一気に指定 ※safari対策
            stroke-dashoffset: 64px;
            transition: 0.5s;
          }
        }
        &.active {
          svg path {
            stroke-dashoffset: 0px;
          }
        }
        @include m.hover_device {
          &:hover {
            svg path {
              stroke-dashoffset: 0px;
            }
          }
        }
      }
    }
    .history_wrapper {
      margin-top: f.clamp_prf(36px, 50px);
      font-size: 14px;
      font-weight: v.$fw_regular_400;
      h3 {
        margin-top: f.clamp_prf(22px, 22px);
        font-size: f.clamp_prf(17px, 18px);
        font-family: v.$montserrat;
        font-weight: v.$fw_semibold_600;
        line-height: 1.75;
        letter-spacing: 0.1em;
        color: v.$blue;
      }
      ul {
        li {
          line-height: 271.5%;
          padding-left: 20px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 17px;
            left: 0;
            width: 3px;
            height: 3px;
            background: v.$blue;
            border-radius: 100vh;
          }
        }
      }
      transition: all 0.5s;
      &.switch {
        opacity: 0;
        transform: translateY(10px);
      }
    }
  }
}
#p__schedule {
  .calender_wrapper {
    @include m.content_width(1108px);
    margin-top: 42px;
    font-family: v.$montserrat;
    font-weight: v.$fw_semibold_600;
    @include m.sp {
      margin-top: 60px;
    }
  }
  .calender_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    position: relative;
    .head_l {
      display: flex;
      align-items: center;
      .month {
        font-size: 50px;
        letter-spacing: 5px;
        white-space: nowrap;
      }
      .year_month {
        font-size: 14px;
        line-height: 1.2;
        white-space: nowrap;
      }
    }
    .head_r {
      display: flex;
      align-items: center;
      margin-right: -20px;
      @include m.sp {
        position: absolute;
        right: clamp(-20px, -4.5vw, -14px);
        top: clamp(-54px, -10.5vw, -38px);
        margin-right: 0;
      }
    }
    .c__primary_button {
      width: 94px;
      height: 30px;
      z-index: 1; // 前後ボタンと重なっても大丈夫なように
      @include m.sp {
        width: clamp(88px, 23.5vw, 120px);
        position: absolute;
        right: 0;
      }
      .link_name {
        font-size: 14px;
        padding-bottom: 2px;
      }
      .arrow {
        display: none;
      }
    }
  }
  .month_calender_wrapper {
    transition: 0.4s ease;

    &.reset {
      opacity: 0;
      transform: translateY(10px);
    }
    &.prev_switched {
      animation: prev_switch 0.8s ease forwards;
      @keyframes prev_switch {
        0% {
          opacity: 1;
          transform: translateX(0px);
        }
        49.9% {
          opacity: 0;
          transform: translateX(10px);
        }
        50% {
          opacity: 0;
          transform: translateX(-10px);
        }
        100% {
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }
    &.next_switched {
      animation: next_switch 0.8s ease forwards;
      @keyframes next_switch {
        0% {
          opacity: 1;
          transform: translateX(0px);
        }
        49.9% {
          opacity: 0;
          transform: translateX(-10px);
        }
        50% {
          opacity: 0;
          transform: translateX(10px);
        }
        100% {
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }
    .day_names {
      display: grid;
      grid-template-columns: repeat(7, minmax(0, 1fr));
      .name {
        text-align: center;
      }
    }
    .month_calender {
      margin-top: 8px;
      display: grid;
      grid-template-rows: repeat(5, minmax(0, 1fr));
      grid-template-columns: repeat(7, minmax(0, 1fr));
      .date_item {
        padding: 4px;
        background-color: #fff; // 線の重なりを隠すために必要
        box-shadow: 0 0px 0px 1px v.$blue;
        display: flex;
        flex-direction: column;
        min-height: clamp(100px, 10vw, 140px);
        &.today {
          background: v.$gradation_secondary;
        }
        &.other_month {
          color: #999;
        }
        .date {
          margin: 6px;
        }
        .event_wrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;
          flex-grow: 1;
          .event {
            color: #fff;
            font-size: 12px;
            padding: 3px 7px 3px 7px;
            border-radius: 10px;
            line-height: 170%;
            font-weight: v.$fw_medium_500;
            @include m.line_clamp(3);
            text-align: left;
            cursor: pointer;
          }
        }
      }
    }
    @include m.tab {
      height: 60vh;

      overflow-y: auto;
      overflow-x: hidden;
      @include m.custom_scrollbar;
      padding-right: 20px;

      /* 下部に影をつけて続きがある感じを演出する */
      // 参考リンク https://qiita.com/arowM/items/7437215209ca74822b97
      box-shadow: inset 0em -0.9em 0.8em -18px rgba(0, 0, 0, 0.5);

      /* 最下部までスクロールされた際、この要素が影の上に重ねて表示されることで、影が消えたように見える */
      &::after {
        content: "";
        display: block;
        position: relative;
        z-index: 1000;
        height: 0.8em;
        background-color: #fff;
        /* scrollable に 0.2em のpaddingがついており、そのままだと左右に 0.2em ぶん隙間ができて影が見えてしまう。それを防ぐためのマイナスマージン。*/
        margin: 0 -0.2em;
        width: 120%;
      }

      .day_names {
        display: none;
      }
      .month_calender {
        display: flex;
        flex-direction: column;
        border-top: 1px solid v.$gray;
        // gap: 10px;
        .date_item {
          box-shadow: none;
          border-bottom: 1px solid v.$gray;
          flex-direction: row;
          gap: 10px;
          background-color: transparent; // スクロールのシャドウが見えるようにする

          &.other_month {
            display: none;
          }
          .date_item_wrapper {
            width: 40px;
            flex-shrink: 0;
          }
          .event_wrapper {
            gap: 6px;
            .event {
              padding: 6px 6px 4px;
              @include m.line_clamp(2);
            }
          }
        }
      }
    }
  }
  .back_to_top_button {
    margin: 100px auto 0;
  }
}
#p__contact {
  .contact_description {
    @include m.content_width(v.$content_width_s);
    margin-top: f.clamp_prf(66px, 76px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    font-size: f.clamp_prf(14px, 16px);
    font-weight: v.$fw_medium_500;
    line-height: 237.5%;
    @include m.sp {
      text-align: left;
      line-height: 171.4%;
    }
  }
  form {
    @include m.content_width(v.$content_width_s);
    margin-top: f.clamp_prf(50px, 80px);
  }
  .input_wrapper {
    margin-bottom: f.clamp_prf(30px, 40px);
    display: flex;
    align-items: baseline;

    &.textbox {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: v.$gray;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #86cbe2;
        transform: scaleX(0);
        z-index: 1;
        transition: 0.3s ease;
        transform-origin: left;
      }
      &.active {
        &:after {
          transition: 0.8s ease;
          transform: scaleX(1);
        }
      }
    }
    @include m.tab {
      flex-direction: column;
    }
  }
  #radio_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 40px 0 0 20px;
    @include m.sp {
      margin: 40px 0 0 0;
    }
  }
  .c__contact_label {
    display: block;
    margin-top: 20px;
    margin-bottom: f.clamp_prf(10px, 24px);
    width: 170px;
  }
  .textarea_wrapper {
    margin-bottom: 24px;
    overflow: hidden;
  }
  .agreement_wrapper {
    flex-direction: column;
    label {
      display: inline-block;
      margin-top: f.clamp_prf(20px, 20px);
    }
    .agreement_notice {
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.05em;
    }
    a {
      text-decoration: underline;
      line-height: 1;
      &:hover {
        transition: 0.3s;
        opacity: 0.8;
      }
    }
    .c__contact_label {
      margin: 50px 0 20px;
    }
    .agreement_label {
      margin: 0 0 0 20px;
    }
  }
  .c__contact_textbox {
    @include m.sp {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .c__contact_textarea {
    @include m.sp {
      padding-left: 0;
      padding-right: 0;
    }
  }
  #js__error_existence {
    font-size: f.clamp_prf(14px, 16px);
    font-weight: v.$fw_medium_500;
    display: flex;
    gap: 10px;
    flex-direction: column;
    display: none;
    &.active {
      display: flex;
    }
    .u__error_list {
      display: block;
      font-size: f.clamp_prf(14px, 16px);
      &:last-child {
        margin-bottom: f.clamp_prf(40px, 40px);
      }
    }
  }
  .c__error_message {
    margin-top: 70px;
    margin-bottom: 10px;
    display: none;
    &.active {
      display: block;
    }
  }
  .c__primary_button {
    margin: f.clamp_prf(60px, 100px) auto 0;
  }
}
#p__contact_submitted {
  .submitted_description {
    @include m.content_width(v.$content_width_m);
    margin-top: f.clamp_prf(60px, 80px);
    text-align: center;
    @include m.sp {
      text-align: left;
    }
  }
  .back_to_top {
    margin: f.clamp_prf(60px, 80px) auto 0;
  }
}
#p__terms {
  .terms_section {
    @include m.content_width(v.$content_width_m);
    margin-top: f.clamp_prf(80px, 100px);
  }
  .section_header {
    color: #666;
    font-weight: v.$fw_semibold_600;
    margin-top: f.clamp_prf(40px, 50px);
    font-size: f.clamp_prf(16px, 18px);
  }
  .c__common_text_primary {
    margin-top: f.clamp_prf(20px, 20px);
  }
  ul {
    list-style-type: disc;
    padding: 14px 0 14px 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: v.$fw_light_300;
    li {
      font-size: 15px;
      line-height: 1.5em;
    }
  }
}
#p__notfound {
  .content_inner {
    @include m.content_width(v.$content_width_m);
    margin-top: f.clamp_prf(60px, 80px);
    text-align: center;
    @include m.sp {
      text-align: left;
    }
  }
  .c__head_secondary,
  .c__common_text_primary {
    margin: f.clamp_prf(60px, 80px) auto 0;
    text-align: center;
  }
  .c__primary_button {
    margin: f.clamp_prf(60px, 80px) auto 0;
  }
}
