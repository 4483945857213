@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*=======================================
  ヘッダー
=======================================*/
#header {
  width: 100%;
  height: v.$header_height;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transform: translateY(-10px);
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
  .header_symbol {
    position: fixed;
    top: f.clamp_prf(28px, 28px);
    left: f.clamp_prf(80px, 120px);
    z-index: 20;
    .logo_anchor {
      display: block;
      svg {
        width: f.clamp_prf(44px, 56px);
        height: auto;
        fill: v.$blue;
        transition: 0.3s;
      }
    }
    @include m.tab {
      top: 20px;
      left: 20px;
    }
  }
  .header_inner {
    width: 68%;
    max-width: calc(100% - (100% - v.$pc_min));
    height: 100%;
    margin: 0 auto;
    padding: 0 clamp(30px, 3.6vw, 66px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(0);
      width: 100%;
      height: 100%;
      border-radius: 0 0 20px 20px;
      opacity: 1;
      transition: all 0.5s;
      background: v.$gradation_secondary;
    }
    @include m.tab {
      display: none;
    }
  }
  .header_l {
    z-index: 11;
    font-family: v.$montserrat;
    font-weight: v.$fw_semibold_600;
    font-size: f.clamp_prf(14px, 16px);
    display: flex;
    gap: f.clamp_prf(24px, 30px);
    align-items: center;
    transition: 0.1s;
    @include m.tab {
      display: none;
    }
    .header_link {
      transition: 0.3s;
      @include m.hover_device {
        &:hover {
          @include m.anchor_color(v.$blue);
        }
      }
    }
  }
  .header_r {
    .c__sns_service_icon {
      gap: f.clamp_prf(12px, 20px);
    }
    @include m.tab {
      display: none;
    }
  }
  &.on_fv {
    .header_inner:before {
      opacity: 0;
      filter: blur(20px);
    }
    .header_l {
      color: white;
    }
    .header_symbol .logo_anchor svg {
      fill: white;
    }
  }
  #sp_menu {
    display: none;
    height: calc(f.dvh(100));
    height: 100dvh;
    width: 100%;
    transition: all 0.2s ease-in-out;
    background: v.$gradation_secondary;
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 15;
    @include m.tab {
      display: block;
    }
    &.active {
      @include m.tab {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
    .menu_inner {
      visibility: inherit;
      opacity: inherit;
      margin: clamp(70px, calc(9vh + 8vw), 110px) auto 0; // 端末の高さは横幅にも比例しがちなのでvhとvwを足す
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 160px;
      width: 100%;
    }
    .link_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.3s;
      .link_name {
        font-family: v.$montserrat;
        font-weight: v.$fw_semibold_600;
      }
      .c__navigation_button {
        width: clamp(40px, 12vw, 40px);
        height: clamp(40px, 12vw, 40px);
        svg path {
          stroke-width: 2px;
        }
      }
      @include m.hover_device {
        &:hover {
          @include m.anchor_color(v.$blue);
          .circle {
            box-shadow: 0 0px 6px 0 #87bcce;
          }
        }
      }
    }
    .c__sns_service_icon {
      margin-top: 10px;
    }
    .sp_menu_bottom_copyright {
      position: absolute;
      font-family: v.$montserrat;
      font-weight: v.$fw_medium_500;
      font-size: 12px;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }
  }

  #header_toggle {
    // 線の位置や幅はここで調整
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px; // タップできる範囲の設定
    height: 60px; // タップできる範囲の設定
    cursor: pointer;
    z-index: 20;
    @include m.tab {
      display: block;
    }
    span {
      display: block;
      position: absolute;
      background: v.$purple;
      transition: 0.6s cubic-bezier(0.59, 0.33, 0.49, 1);
    }
    // 横棒
    span:nth-child(1) {
      top: 34px;
      right: 20px;
      width: 30px;
      height: 1px;
    }
    // 縦棒
    span:nth-child(2) {
      top: 20px;
      right: 34px;
      width: 1px;
      height: 30px;
    }
    &.active span:nth-child(1) {
      transform: translate(0, 1px) rotate(180deg);
    }
    &.active span:nth-child(2) {
      transform: translate(0, 0.5px) rotate(270deg);
    }
  }
}

#footer {
  height: v.$footer_height;
  z-index: 8;
  @include m.body_margin;
  background-color: #fff;
  font-family: v.$montserrat;
  @include m.tab {
    height: v.$footer_height_sp;
  }
  .footer_inner {
    width: 76%;
    max-width: calc(100% - (100% - v.$pc_min));
    height: 100%;
    margin: 0 auto;
    height: 100%;
    background: v.$gradation_secondary;
    border-radius: 20px 20px 0 0;
    padding: 0 f.clamp_prf(50px, 66px) f.clamp_prf(50px, 64px);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 40px;
  }
  .footer_l {
    display: flex;
    gap: f.clamp_prf(20px, 30px);
    font-size: f.clamp_prf(14px, 16px);
    font-weight: v.$fw_semibold_600;
    flex-wrap: wrap;
  }
  .footer_r {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-end;
  }
  .c__sns_service_icon {
    justify-content: flex-end;
  }
  .footer_r_bottom {
    display: flex;
    gap: 20px;
    font-size: f.clamp_prf(12px, 14px);
    flex-wrap: wrap;
    justify-content: flex-end;
    .terms {
      font-weight: v.$fw_semibold_600;
    }
    .copyright {
      font-weight: v.$fw_medium_500;
    }
  }
  @include m.tab {
    .footer_inner,
    .footer_l,
    .footer_r,
    .c__sns_service_icon,
    .footer_r_bottom {
      display: none;
    }
  }
  .footer_inner_sp {
    margin: 0 auto;
    width: clamp(240px, 75vw, 280px);
    height: 40px;
    background: v.$gradation_secondary;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    align-items: center;
    display: none;
    @include m.tab {
      display: flex;
    }
    .copyright_sp {
      font-size: clamp(10px, 4vw, 12px);
    }
  }
  .footer_link {
    transition: 0.3s;
    @include m.hover_device {
      &:hover {
        @include m.anchor_color(v.$blue);
      }
    }
  }
}

/*=======================================
  flex系
=======================================*/
//常にflex
.l__main_content {
  padding-top: clamp(100px, 16vw, 180px);
  padding-bottom: f.clamp_prf(100px, 200px);
  background-color: #fff;
  @include m.body_margin;
}

/*=======================================
  マウスストーカー
=======================================*/
#mouse_stalker {
  pointer-events: none;
  z-index: 99999;
  @include m.hover_device {
    position: fixed;
    top: -13px;
    left: -13px;
    width: 26px;
    height: 26px;
    border: 1px solid #9ad4e7;
    border-radius: 100vh;
    transform: translate(0, 0);
    transition: 1.9s cubic-bezier(0.075, 0.82, 0.165, 1);
    mix-blend-mode: multiply;
    &.disabled {
      opacity: 0;
    }
    // ホバーした際の挙動
    &.stalker_cursor_pointer {
      top: -30px;
      left: -30px;
      width: 60px;
      height: 60px;
      background: #70bed815;
    }
  }
  @include m.touch_device {
    display: none;
  }
}

#l__funclub_button {
  position: fixed;
  bottom: clamp(20px, 3vw, 30px);
  right: clamp(16px, 3vw, 40px);
  width: 10px;
  height: 10px;
  z-index: 7;
  margin: 0 auto;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: 0.5s cubic-bezier(0.24, 1.4, 0.47, 1.01);
  transform-origin: right bottom;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    width: clamp(140px, 10vw, 160px);
    height: clamp(240px, 18vw, 280px);
    @include m.tab {
      width: clamp(100px, 16vw, 130px);
      height: clamp(106px, 18vw, 136px);
    }
  }
  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 0 0 auto;
    pointer-events: auto;
  }
  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    transition: 0.2s;
  }
  .large {
    @include m.tab {
      opacity: 0;
    }
  }
  .small {
    opacity: 0;
    @include m.tab {
      opacity: 1;
    }
  }

  // ファーストビューを抜けたら小さくする
  &.fv_scrolled {
    width: clamp(100px, 9vw, 140px);
    height: clamp(106px, 10vw, 140px);
    img.large {
      opacity: 0;
    }
    img.small {
      opacity: 1;
    }
  }
  @include m.hover_device {
    &:hover {
      transform: scale(1.04);
    }
  }
}

/*=======================================
  イベントモーダルまわりの設定
=======================================*/
#l__modal_wrapper {
  animation: modal_fadein 0.5s forwards;
  &.fadeout {
    animation: modal_fadeout 0.5s forwards;
  }

  @keyframes modal_fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes modal_fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .modal_overlay_for_click {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    margin: 0 auto;
  }

  .event_modal {
    position: relative;
    padding: clamp(60px, 6vw, 80px) clamp(40px, 6vw, 80px);
    width: 100%;
    max-width: v.$content_width_s;
    width: 80vw;
    height: 100%;
    max-height: 80dvh;
    background-color: #fff;
    box-shadow: 0 0px 6px 0 #87bcce;
    border-radius: f.clamp_prf(10px, 20px);
  }
  .modal_inner {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include m.custom_scrollbar;
    padding-right: 20px;
  }
  .article_head_info {
    display: flex;
    flex-direction: column;
    gap: f.clamp_prf(6px, 6px);
    padding-bottom: f.clamp_prf(20px, 20px);
    width: 100%;
    position: relative;
    .info_above {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: f.clamp_prf(28px, 28px);
      font-weight: v.$fw_bold_700;
      font-size: f.clamp_prf(14px, 14px);
      font-family: v.$montserrat;
      letter-spacing: f.px2em(14, 0.84);
      .date {
        white-space: nowrap;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: -16px;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background: #a0a0a0;
          border-radius: 100vh;
        }
      }
      .category {
        white-space: nowrap;
      }
    }
    .title {
      margin-top: f.clamp_prf(10px, 10px);
      width: 100%;
      font-weight: v.$fw_medium_500;
      font-size: f.clamp_prf(14px, 16px);
      text-align: justify;
      line-height: 175%;
    }
  }
  .c__article_body {
    margin-top: f.clamp_prf(20px, 20px);
  }
  .close_button {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px; // タップできる範囲の設定
    height: 70px; // タップできる範囲の設定
    cursor: pointer;
    z-index: 20;
    span {
      display: block;
      position: absolute;
      background: v.$purple;
      pointer-events: none;
    }
    // 横棒
    span:nth-child(1) {
      top: 34px;
      right: 20px;
      width: 30px;
      height: 1px;
      transform: rotate(45deg);
    }
    // 縦棒
    span:nth-child(2) {
      top: 20px;
      right: 34px;
      width: 1px;
      height: 30px;
      transform: rotate(45deg);
    }
  }
}
