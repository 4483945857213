@use "../foundation/functions" as f;
@use "../foundation/variables" as v;

/*=======================================
  ページ制御系
=======================================*/
// pcのみで反映させ、tab以下に引継ぎたくない場合
@mixin pc {
  @media (min-width: (v.$tab_max + 1)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: (v.$tab_max)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: (v.$sp_max)) {
    @content;
  }
}
// 374px以下の小さめの端末で特別な対応が必要な場合
@mixin sp_s {
  @media (max-width: 374px) {
    @content;
  }
}
// マウス系のデバイス
@mixin hover_device {
  @media (any-hover: hover) {
    @content;
  }
}
// タッチ系のデバイス
@mixin touch_device {
  @media (any-hover: none) {
    @content;
  }
}
//縦長
@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}
//横長
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

// aタグの色を一括で変更したい場合(aタグの中で使う)
@mixin anchor_color($color) {
  &:visited,
  &:hover,
  &:active {
    color: $color;
  }
}

// 点線を生成
@mixin dashed_border($height: 1px, $dash: 3px, $spacing: 5px, $color: #aaa) {
  display: block;
  height: $height;
  position: relative;
  &::before {
    content: "";
    background-image: linear-gradient(
      to right,
      $color 0px,
      $color $dash,
      transparent $dash,
      transparent #{$dash + $spacing}
    );
    background-size: #{$dash + $spacing} $height;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
// テキストを省略
@mixin line_clamp($line) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}
// テキストを日英ともに縦書き
@mixin text_vertical {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}

@mixin fontawesome($type) {
  @if $type == fas {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_fas;
  } @else if $type == far {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_far;
  } @else if $type == fal {
    font-family: "Font Awesome 5 Free";
    font-weight: $fontawesome_weight_fal;
  } @else if $type == fab {
    font-family: "Font Awesome 5 Brands";
    font-weight: $fontawesome_weight_fab;
  }
}
@mixin content_width($width: v.$pc_min) {
  max-width: calc($width + v.$body_pd + v.$body_pd);
  width: 100%;
  padding-left: v.$body_pd;
  padding-right: v.$body_pd;
  margin-left: auto;
  margin-right: auto;
}

@mixin body_margin() {
  margin: 0 v.$body_pd;
  @include tab {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@mixin external_link_icon {
  position: relative;
  padding-right: 1.5em;
  &:before {
    content: "";
    display: block;
    position: absolute;
    // background-image: url(../../img/icon/new_window_line.svg);
    background-repeat: no-repeat;
    width: 0.8em;
    height: 0.8em;
    bottom: 2px;
    right: 0.3em;
  }
}
@mixin gradation_text($color: linear-gradient(90deg, #8a2387 0%, #e94057 50%, #f27121 100%)) {
  background: $color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  &::selection {
    -webkit-background-clip: text;
    -webkit-text-fill-color: v.$selection_text_color;
    background-clip: text;
    text-fill-color: v.$selection_text_color;
  }
  span {
    &::selection {
      -webkit-background-clip: text;
      -webkit-text-fill-color: v.$selection_text_color;
      background-clip: text;
      text-fill-color: v.$selection_text_color;
    }
  }
}

@mixin custom_scrollbar($width: 4px, $track_color: #eee, $thumb_color: v.$logo_blue) {
    &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 100vh;
    background: $track_color;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb_color;
    border-radius: 100vh;
    box-shadow: none;
  }
}
// 親要素の幅を超えて画面横幅いっぱいに広げる
@mixin spread_over_parent() {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

// 英語の単語が途中で改行されないようにしたりして改行をいい感じにする
@mixin adjust_break_word() {
  overflow-wrap: break-word;
  word-break: break-word;
}

/*===================================================
  アニメーションディレイ
  子要素のアニメーションにディレイをかけられる
===================================================*/
@mixin anim_delay($magnification: 0.05) {
  @for $i from 0 through 16 {
    &.delay#{$i} {
      animation-delay: #{$i * $magnification}s;
    }
  }
}
@mixin tran_delay($magnification: 0.05) {
  @for $i from 0 through 12 {
    &.delay#{$i} {
      transition-delay: #{$i * $magnification}s;
    }
  }
}
